.home {
  position: relative;
  background-color: var(--color-gray-300);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-12xl) var(--padding-3xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
