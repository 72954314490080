@font-face {
  font-family: "Cabinet Grotesk";
  src: url("/public/CabinetGrotesk-Extrabold.otf");
  font-weight: 800;
}
@font-face {
  font-family: "Satoshi";
  src: url("/public/Satoshi-Medium.otf");
  font-weight: 500;
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-satoshi: Satoshi;
  --font-cabinet-grotesk: "Cabinet Grotesk";

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-mini: 15px;
  --font-size-xl: 20px;
  --font-size-6xl: 25px;
  --font-size-mid: 17px;

  /* Colors */
  --color-gray-100: #fcfcfc;
  --color-gray-200: #1c1c1c;
  --color-gray-300: #141414;
  --color-gray-500: #020202;
  --color-gray-400: rgba(255, 255, 255, 0.5);
  --color-white: #fff;
  --color-limegreen: #81ff6c;
  --color-crimson: #eb485c;
  --color-cornflowerblue: #1e7bc6;
  --color-black: #000;

  /* Gaps */
  --gap-11xl: 30px;
  --gap-smi: 13px;
  --gap-3xs: 10px;
  --gap-8xs: 5px;
  --gap-26xl: 45px;
  --gap-31xl: 50px;

  /* Paddings */
  --padding-12xl: 31px;
  --padding-3xl: 22px;
  --padding-3xs: 10px;
  --padding-5xs: 8px;
  --padding-base: 16px;
  --padding-11xl: 30px;
  --padding-9xs: 4px;
  --padding-12xs: 1px;
  --padding-8xs: 5px;
  --padding-48xl: 67px;

  /* border radiuses */
  --br-9xs: 4px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-8xs: 5px;
}
