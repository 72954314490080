.connectedAccount {
  position: relative;
  font-weight: 800;
}
.connectedAccountWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-8xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.telegram {
  position: relative;
  font-weight: 800;
  opacity: 0.5;
}
.telegramUsername {
  position: relative;
  font-weight: 500;
}
.telegramUsernameWrapper {
  flex: 1;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--font-size-mid);
  color: var(--color-cornflowerblue);
  font-family: var(--font-satoshi);
}
.proOnlyWrapper,
.telegramParent,
.telegramUsernameWrapper {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.telegramParent {
  align-self: stretch;
  border: 1px solid var(--color-white);
  padding: var(--padding-3xs);
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.proOnlyWrapper {
  flex: 1;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--font-size-mid);
  color: var(--color-crimson);
  font-family: var(--font-satoshi);
}
.frameParent {
  align-self: stretch;
  border: 1px solid var(--color-white);
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs) 0 0 var(--padding-12xs);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-cabinet-grotesk);
}
