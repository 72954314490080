.accountChild {
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  overflow: hidden;
}
.accountChild,
.jIcon,
.scodeAi {
  position: relative;
}
.jIcon {
  width: 69.7px;
  height: 27.65px;
  flex-shrink: 0;
}
.scodeAi {
  font-weight: 800;
}
.telegramIcon {
  position: relative;
  border-radius: var(--br-7xs);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.telegramParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.frameWrapper,
.jParent {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.frameWrapper {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.jParent {
  position: absolute;
  top: -138.12px;
  left: -22px;
  width: 330px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}
.chatInfo,
.chatValue {
  align-self: stretch;
  position: relative;
  font-weight: 800;
}
.chatValue {
  display: inline-block;
  height: 30px;
  flex-shrink: 0;
}
.chatInfo {
  font-size: var(--font-size-xl);
  color: var(--color-limegreen);
}
.chatValueParent,
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chatValueParent {
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.frameContainer {
  flex: 1;
  align-items: center;
}
.shopButton {
  position: relative;
  font-size: var(--font-size-mid);
  font-weight: 500;
  font-family: var(--font-satoshi);
  text-align: center;
}
.frameGroup {
  position: absolute;
  top: -68.36px;
  left: -22px;
  border: 1px solid #60d66a;
  box-sizing: border-box;
  width: 330px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.frameParent {
  width: 330px;
  height: 376.76px;
}
.accountItem,
.frameParent,
.logout {
  position: relative;
  flex-shrink: 0;
}
.accountItem {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.logout {
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--font-satoshi);
  color: #ff4545;
  text-align: center;
  display: inline-block;
  width: 300px;
  height: 52px;
}
.account {
  position: relative;
  background-color: var(--color-gray-300);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-3xl) var(--padding-12xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
  font-family: var(--font-cabinet-grotesk);
}
