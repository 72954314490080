.jIcon {
  position: relative;
  width: 138.46px;
  height: 54.92px;
  flex-shrink: 0;
}
.aCuttingEdgeSolution,
.scodeAiThe {
  position: relative;
  font-weight: 800;
  display: inline-block;
  width: 305px;
}
.scodeAiThe {
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--font-satoshi);
  color: var(--color-gray-400);
  width: 300px;
}
.aCuttingEdgeSolutionForAlParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-smi);
}
.login {
  position: relative;
  font-weight: 500;
}
.loginWrapper {
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  width: 101px;
  height: 30px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.community {
  position: relative;
  font-size: var(--font-size-mid);
  font-weight: 500;
  color: var(--color-gray-100);
  display: inline-block;
  width: 127px;
  flex-shrink: 0;
}
.frameGroup,
.frameParent,
.jParent {
  display: flex;
  justify-content: center;
}
.frameGroup {
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-satoshi);
}
.frameParent,
.jParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-31xl);
}
.jParent {
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
  font-family: var(--font-cabinet-grotesk);
}
