.jIcon {
  position: relative;
  width: 138.46px;
  height: 54.92px;
  flex-shrink: 0;
}
.studentPack {
  position: relative;
  font-weight: 800;
}
.studentPackWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.php149460 {
  position: relative;
  font-size: var(--font-size-xl);
  font-weight: 800;
  color: var(--color-limegreen);
}
.wordsParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: var(--font-size-mini);
}
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-6xl);
}
.allBasicPack {
  margin: 0;
}
.allBasicPackContainer {
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--font-satoshi);
  color: var(--color-gray-400);
  display: inline-block;
  width: 300px;
  height: 76px;
  flex-shrink: 0;
}
.featureParent {
  height: 72px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.model24BetterContainer {
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--font-satoshi);
  color: var(--color-gray-400);
  display: inline-block;
  width: 300px;
  height: 52px;
  flex-shrink: 0;
}
.powerParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  gap: var(--gap-smi);
}
.frameParent,
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.logoIcon {
  position: relative;
  width: 61.58px;
  height: 24px;
  flex-shrink: 0;
}
.buy,
.jParent {
  display: flex;
  justify-content: center;
}
.buy {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--color-white);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  height: 50px;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  align-items: center;
}
.jParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-cabinet-grotesk);
}
