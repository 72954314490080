.basicButton,
.proButton,
.studentButton {
  position: relative;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}
.basicButton {
  color: var(--color-white);
  width: 49px;
}
.proButton,
.studentButton {
  width: 88px;
}
.proButton {
  width: 42px;
}
.basicButtonParent,
.student,
.studentInner {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.basicButtonParent {
  flex-direction: row;
  padding: 0 0 var(--padding-11xl);
  gap: var(--gap-3xs);
}
.student,
.studentInner {
  flex-direction: column;
  box-sizing: border-box;
}
.studentInner {
  height: 525px;
  flex-shrink: 0;
  padding: var(--padding-3xs);
}
.student {
  position: relative;
  background-color: var(--color-gray-300);
  width: 100vw;
  height: 100vh;
  padding: var(--padding-12xl) var(--padding-3xl);
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-gray-400);
  font-family: var(--font-satoshi);
}
