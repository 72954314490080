.jIcon {
  position: relative;
  width: 138.46px;
  height: 54.92px;
  flex-shrink: 0;
}
.welcomeToScode {
  position: relative;
  font-weight: 800;
  display: inline-block;
  width: 305px;
}
.imGladTo {
  margin: 0;
}
.imGladToContainer {
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--font-satoshi);
  color: var(--color-gray-400);
  display: inline-block;
  width: 294px;
}
.jParent,
.welcomeToScodeAiParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xs);
}
.jParent {
  align-self: stretch;
  gap: var(--gap-11xl);
}
.telegramIcon {
  position: relative;
  border-radius: var(--br-7xs);
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.telegram {
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-200);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}
.telegramParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.joinToApi {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  opacity: 0.5;
}
.joinToApiWaitlistWrapper {
  position: relative;
  width: 155px;
  height: 20px;
  flex-shrink: 0;
  font-size: var(--font-size-mini);
  font-family: var(--font-satoshi);
}
.login {
  position: relative;
  background-color: var(--color-gray-300);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-48xl) var(--padding-3xl) var(--padding-12xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-26xl);
  text-align: center;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
  font-family: var(--font-cabinet-grotesk);
}
