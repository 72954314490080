.jIcon {
  position: relative;
  width: 138.46px;
  height: 54.92px;
  flex-shrink: 0;
}
.jWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.checkYouInbox,
.thankYouFor {
  position: relative;
  font-weight: 800;
}
.checkYouInbox {
  font-size: var(--font-size-lg);
  font-weight: 500;
  font-family: var(--font-satoshi);
  color: var(--color-gray-400);
  text-align: center;
  display: inline-block;
  width: 300px;
  height: 52px;
  flex-shrink: 0;
}
.frameParent,
.success {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frameParent {
  height: 525px;
  flex-shrink: 0;
  padding: var(--padding-3xs);
  gap: var(--gap-3xs);
}
.success {
  position: relative;
  background-color: var(--color-gray-300);
  width: 100vw;
  height: 100vh;
  padding: var(--padding-12xl) var(--padding-3xl);
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
  font-family: var(--font-cabinet-grotesk);
}
